@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #ad5389; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #3c1053,
    #ad5389
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #3c1053,
    #ad5389
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#tooltip {
  display: none;
}

#tooltip[data-show] {
  display: block;
}
